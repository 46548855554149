'use client'
import CustomModal from '@/components/globals/custom-model'
import { useModal } from '@/contexts/modal-provider'
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { ChevronDown, LineChart, LogOut, Menu as MenuIcon } from 'lucide-react'
import Link from 'next/link'
import { useRouter } from 'next/navigation'
import Logo from './logo'
import { deleteCookie } from '@/lib/cookies'
import { OrderView } from './order/order.view'
import { HtmlHTMLAttributes } from 'react'
import { userSessionContext } from '@/contexts/user-context'
type NavLink = {
  href: string
  children: React.ReactNode
} & HtmlHTMLAttributes<HTMLAnchorElement>
export const NavLink = ({ href, children, ...rest }: NavLink) => (
  <li>
    <Link
      {...rest}
      href={href}
      className="flex font-bold items-center text-sm gap-1 transition-colors hover:text-blue-600"
    >
      {children}
    </Link>
  </li>
)

const Header = () => {
  const { setOpen } = useModal()
  const openModal = () => {
    setOpen(
      <CustomModal type="modal" subheading="Encomendar">
        <OrderView />
      </CustomModal>,
    )
  }
  return (
    <header className="w-full top-0 shadow-sm backdrop-blur-md z-[60] m-auto">
      <div className="flex items-center justify-between w-full p-4">
        <div className="flex  lg:items-center">
          <Logo />
        </div>
        <nav className="max-lg:hidden flex lg:block  items-center gap-[80px]">
          <ul className="flex space-x-4 gap-3 flex-wrap justify-between">
            <NavLink href="/search?transaction=Investimento">
              Investimento
            </NavLink>
            <NavLink href="/search?transaction=Aluguel">Aluguel</NavLink>
            <NavLink href="/search?transaction=Venda">Venda</NavLink>
            <NavLink href="/search?transaction=Temporada">Temporada</NavLink>
            <div
              onClick={openModal}
              className="flex cursor-pointer items-center text-sm gap-1 transition-colors hover:text-blue-600"
            >
              <p className="font-bold">Encomendar</p>
            </div>
          </ul>
        </nav>
        <div className="flex justify-end  items-center gap-2">
          <div className="flex text-center items-center justify-center space-x-2 max-md:hidden">
            <Link
              href={'/formulary'}
              className="rounded-lg text-center p-3 text-base font-[600] hover:bg-[#063B89]/10"
            >
              Anuncie
            </Link>
            <UserMenu />
          </div>
          <Menu />
        </div>
      </div>
    </header>
  )
}
export default Header

const Menu = () => {
  const { setOpen, setClose } = useModal()
  const openModal = () => {
    setOpen(
      <CustomModal type="modal" subheading="Encomendar">
        <OrderView />
      </CustomModal>,
    )
  }
  const handleOpen = () => {
    setOpen(
      <CustomModal subheading="Menu" type="sheet">
        <div className="flex h-full flex-col justify-between p-4">
          <nav>
            <ul className="space-y-8">
              <NavLink href="/search?transaction=Investimento" onClick={setClose}>Investimento</NavLink>
              <NavLink href="/search?transaction=Aluguel" onClick={setClose}>Aluguel</NavLink>
              <NavLink href="/search?transaction=Venda" onClick={setClose}>Venda</NavLink>
              <NavLink href="/search?transaction=Temporada" onClick={setClose}>Temporada</NavLink>
              <NavLink
                href={'/formulary'}
                onClick={setClose}
              >
                Anuncie
              </NavLink>
              <Link href="#"  className="flex font-bold items-center text-sm gap-1 transition-colors hover:text-blue-600" onClick={openModal}>Encomendar</Link>
            </ul>
          </nav>
          <UserMenu />
        </div>
      </CustomModal>,
    )
  }

  return (
    <MenuIcon
      width={35}
      height={35}
      className="rounded-sm border border-[#cfd3da] border-border p-1 lg:hidden"
      onClick={handleOpen}
    />
  )
}

const UserMenu = () => {
  const { setClose } = useModal()
  const { token, user, setToken, updateUser } = userSessionContext()
  const route = useRouter()
  const signOut = ()=> {
    deleteCookie('session.user')
    deleteCookie('favs')
    setToken("")
    updateUser({})
  }
  if (token && user)
    return (
      <DropdownMenu>
        <DropdownMenuTrigger className="flex items-center justify-between gap-2 rounded-xl border p-2">
          <div className="flex gap-2 items-center">
            <Avatar className="h-[30px] w-[30px]">
              <AvatarImage
                className={'border-none outline-none'}
                src={user?.Avatar}
                alt="@shadcn"
              />
              <AvatarFallback>
                {user?.Name[0].toString().toUpperCase()}
              </AvatarFallback>
            </Avatar>
            <p className="w-[75px] truncate">{user?.Name}</p>
          </div>
          <ChevronDown />
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-[15rem]">
          <DropdownMenuLabel>Minha conta</DropdownMenuLabel>
          <DropdownMenuSeparator />
          <DropdownMenuItem
            className="flex w-full items-center justify-between hover:bg-foreground/10"
            onClick={() =>{
              setClose()
              route.push('/dashboard')
            }}
          >
            Minha conta
            <LineChart size={16} />
          </DropdownMenuItem>
          <DropdownMenuItem
            className="flex w-full items-center justify-between hover:bg-foreground/10"
            onClick={signOut}
          >
            Sair
            <LogOut size={16} color="red" />
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    )
  return (
    <div className="max-[420px]:flex-col flex w-full gap-2 items-center ">
      <Link
        href="/sign-in"
        onClick={setClose}
        className="flex w-full items-center justify-center whitespace-nowrap rounded-md bg-[#4279ca] px-5 py-3 text-base font-medium text-white outline-none ring-offset-background transition-colors hover:bg-[#4279ca]/90 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50"
      >
        Login
      </Link>
      <Link
        href={'/sign-up'}
        onClick={setClose}
        className="flex lg:hidden items-center w-full justify-center whitespace-nowrap rounded-md bg-[#4279ca] px-5 py-3 text-base font-medium text-white outline-none ring-offset-background transition-colors hover:bg-[#4279ca]/90 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50"
      >
        Crie sua conta
      </Link>
    </div>
  )
}
