"use client"

import { useMutation } from "@tanstack/react-query"
import { Button } from "../ui/button"
import { Input } from "../ui/input"
import { toast } from "sonner"
import { fetcher } from "@/lib/fetcher"
import { Routes } from "@/@types/api/routes.enum"

export const FormSubscript = () => {
  const mutation = useMutation({
    mutationFn: async (email: string) => await fetcher(Routes.Subscript, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: email})
    }),
    onSuccess: (response: any) => toast.success(response.message || "Salvo com sucesso"),
    onError: (e: any) => {
      toast.error(e.detail as any || "Não foi possivel")
    }
  })
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    mutation.mutate(formData.get('email') as string);
  };
  return <form onSubmit={handleSubmit} method="post" className="flex flex-wrap gap-4 max-sm:flex-col">
    <Input
      type="email"
      name="email"
      placeholder="Digite seu e-mail"
      className="placeholder:text-foreground-90 flex flex-1"
    />
    <Button className="flex-2 flex">Inscreva-se</Button>
  </form>
}